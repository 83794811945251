import type { CartAddLineItemAction } from '@commercetools/platform-sdk'

export interface CreateLineItemSchema {
  productId: string
  variantId: number
  quantity: number
}

function getLineItemAction({ productId, variantId, quantity }: CreateLineItemSchema): CartAddLineItemAction {
  return {
    action: 'addLineItem',
    productId,
    variantId,
    quantity,
    custom: {
      type: {
        typeId: 'type',
        key: 'lineItemAndOrderLineType',
      },
    },
  }
}

export default getLineItemAction
