import { Button, useToast, UseToastOptions } from '@chakra-ui/react'
import type { CartRemoveLineItemAction, CartUpdateAction } from '@commercetools/platform-sdk'
import { useMutation } from '@tanstack/react-query'
import useTranslation from 'next-translate/useTranslation'
import { useCallback } from 'react'

import addLineItem from 'commercetools/cart/addLineItem'
import getLineItemAction from 'commercetools/utils/getLineItemAction'
import useCartAction from 'components/modules/Product/hooks/useCartAction'
import useBusinessUnit from 'utils/Providers/BusinessUnit/useBusinessUnit'
import useCart from 'utils/Providers/Cart/useCart'

export interface AddToCartSchema {
  productId: string
  variantId: number
  quantity: number
  erpWarehouse: string
  erpFacility: string
}

const toastOptions: UseToastOptions = { position: 'top-right', isClosable: true }

const setCustomCartWarehouseActions = (erpWarehouse: string, erpFacility: string): CartUpdateAction[] => {
  return [
    { action: 'setCustomField', name: 'erpWarehouse', value: erpWarehouse },
    { action: 'setCustomField', name: 'erpFacility', value: erpFacility },
  ]
}

/**
 * Returns a function that can be used to submit the AddToCart form.
 */
function useSubmitAddToCart({ productId, variantId, quantity, erpWarehouse, erpFacility }: AddToCartSchema) {
  const { mutateAsync, isLoading: isLoadingAddToCart } = useMutation({ mutationFn: addLineItem })
  const { t } = useTranslation('common')
  const toast = useToast()
  const { refetch: updateCart, data: cart, isLoading: isLoadingCart } = useCart()
  const {
    data: { key: businessUnitKey },
  } = useBusinessUnit()

  const { id: cartId, version: cartVersion } = cart || {}
  const { onSubmit: onUpdateCart, isSubmitting } = useCartAction()

  const cartIsEmpty = cart?.lineItems.length === 0

  const { erpWarehouse: cartErpWarehouse, erpFacility: cartErpFacility } = cart?.custom?.fields || {}

  /**
   * Set erpWarehouse and erpFacility for cart using cart custom fields
   */
  const setCartWarehouseAndFacility = useCallback(async () => {
    return await onUpdateCart(setCustomCartWarehouseActions(erpWarehouse, erpFacility))
  }, [erpFacility, erpWarehouse, onUpdateCart])

  /**
   * If cart has different warehouse or facility than product, user can remove lineItems from cart,
   * set new erpWarehouse and erpFacility and add current line item to cart
   */
  const onLineItemReplace = useCallback(async () => {
    const removeLineItemsActions: CartRemoveLineItemAction[] | undefined = cart?.lineItems.map((item) => ({
      action: 'removeLineItem',
      lineItemId: item.id,
    }))

    return await onUpdateCart([
      ...(removeLineItemsActions ? removeLineItemsActions : []),
      ...setCustomCartWarehouseActions(erpWarehouse, erpFacility),
      getLineItemAction({
        productId,
        variantId,
        quantity: quantity === 0 ? 1 : quantity,
      }),
    ])
  }, [cart?.lineItems, erpFacility, erpWarehouse, onUpdateCart, productId, quantity, variantId])

  const onSubmit = useCallback<VoidFunction>(async () => {
    let updatedCart

    if (!cartId || !cartVersion) {
      return
    }

    if (!erpWarehouse && !erpFacility) {
      toast({
        title: t('components.cart.alerts.missingWarehouseAndFacility'),
        status: 'error',
        ...toastOptions,
      })
      return
    }

    /**
     * Check if current cart has erpWarehouse and erpFacility, if not then set from current line item.
     * If current cart has erpWarehouse and erpFacility but it is empty, then then set from current line item.
     * If current cart has erpWarehouse and erpFacility then check if current line item has the same erpWarehouse and erpFacility,
     * if not then show toast with option to replace
     */

    if ((!cartErpFacility && !cartErpWarehouse) || cartIsEmpty) {
      updatedCart = await setCartWarehouseAndFacility()
    } else {
      if (erpFacility !== cartErpFacility && erpWarehouse !== cartErpWarehouse) {
        // Open modal
        toast({
          title: t('components.cart.alerts.currentItemHaveDifferentWarehouse'),
          description: (
            <Button variant="secondary" onClick={onLineItemReplace} mt="2">
              {t('components.cart.alerts.replaceTheCartWithCurrentItem')}
            </Button>
          ),
          status: 'error',
          ...toastOptions,
        })
        return
      }
    }

    try {
      await mutateAsync({
        businessUnitKey,
        productId,
        variantId,
        quantity: quantity === 0 ? 1 : quantity,
        cartId,
        cartVersion: updatedCart?.data?.version || cartVersion,
      }),
        await updateCart(),
        toast({
          title: t('components.cart.alerts.productAddedToCard'),
          status: 'success',
          ...toastOptions,
        })
    } catch (error) {
      toast({
        title: t('components.cart.alerts.notAbleToAddToCard'),
        status: 'error',
        ...toastOptions,
      })
    }
  }, [
    businessUnitKey,
    cartErpFacility,
    cartErpWarehouse,
    cartId,
    cartIsEmpty,
    cartVersion,
    erpFacility,
    erpWarehouse,
    mutateAsync,
    onLineItemReplace,
    productId,
    quantity,
    setCartWarehouseAndFacility,
    t,
    toast,
    updateCart,
    variantId,
  ])

  return {
    onSubmit,
    isSubmitting: isLoadingCart || isLoadingAddToCart || isSubmitting,
  }
}

export default useSubmitAddToCart
