import { Box, Flex, ListItem, Stack, Text } from '@chakra-ui/react'
import type { Product, ProductProjection } from '@commercetools/platform-sdk'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent, useState } from 'react'

import { DT, DD } from 'components/elements/DescriptionListElements'
import LeadTime from 'components/elements/LeadTime'
import Price from 'components/elements/Price'
import ProductItemNumber from 'components/elements/ProductItemNumber'
import ProductLink from 'components/elements/ProductLink'
import getAttributeValue from 'components/modules/Product/utils/getAttributeValue'
import ProductListAttributes from 'components/modules/Products/ProductsList/ProductListAttributes'
import ProductListOrderControls from 'components/modules/Products/ProductsList/ProductListOrderControls'
import AddToShoppingListButton from 'components/modules/ShoppingList/AddToShoppingList/Button'

interface ProductsListItemProps extends Omit<ProductProjection, 'key'> {
  // we use `productKey`, because we cannot use `key` as a prop name.
  // @see https://reactjs.org/warnings/special-props.html
  productKey: Product['key']
}

/**
 * Used for E2E tests.
 */
const TAG = 'ProductsListItem'

const ProductsListItem: FunctionComponent<ProductsListItemProps> = ({ id, productKey, masterVariant, categories }) => {
  const { t } = useTranslation('product')

  const { erpUnitOfMeasure, erpMinQuantity, erpMaxQuantity, erpWarehouse, erpFacility } =
    masterVariant?.price?.custom?.fields || {}

  const [quantity, setQuantity] = useState(erpMinQuantity)

  return (
    <Stack
      as={ListItem}
      direction="row"
      flexWrap="wrap"
      justifyContent="space-between"
      alignItems="flex-start"
      data-product-id={id}
      data-testid={TAG}
      border="1px"
      borderColor="gray.300"
      padding="4"
      spacing={{ base: 0, md: 2 }}
    >
      <Box minW="220px">
        <ProductLink productKey={productKey} masterVariant={masterVariant} categories={categories} isLarge>
          <Box mt="2">
            <Flex flexWrap="wrap">
              <DT>{t('components.header.kanthalItemNumber')}</DT>
              <DD ml="1">{getAttributeValue('erpItemNumber', masterVariant.attributes)}</DD>
            </Flex>
            <ProductItemNumber price={masterVariant?.price} />
            <ProductListAttributes masterVariant={masterVariant} />
          </Box>
        </ProductLink>
      </Box>
      <Flex
        alignItems="flex-end"
        flexDirection={{ base: 'row-reverse', lg: 'row' }}
        w={{ base: 'full', sm: 'max-content' }}
      >
        <AddToShoppingListButton
          productId={id}
          variantId={masterVariant.id}
          quantity={1}
          erpFacility={erpFacility}
          erpWarehouse={erpWarehouse}
          mb="2"
        />
        <Box w="full">
          <Flex maxW="220px">
            <DT>{t('components.detail.leadTime')}</DT>
            <DD ml="1">
              <LeadTime masterVariant={masterVariant} />
            </DD>
          </Flex>
          <Flex maxW="220px" fontSize="lg" align="center" justifyContent={{ base: 'space-between', sm: 'flex-start' }}>
            <Text fontWeight="bold" mr="2">
              {t('components.detail.yourUnitPrice')}
            </Text>
            <Price price={masterVariant.price} quantity={quantity} />
          </Flex>
          <Flex mt="2" w="full">
            <ProductListOrderControls
              productId={id}
              variantId={masterVariant.id}
              minQuantity={erpMinQuantity}
              maxQuantity={erpMaxQuantity}
              quantity={quantity}
              setQuantity={setQuantity}
              erpWarehouse={erpWarehouse}
              erpFacility={erpFacility}
              unit={erpUnitOfMeasure}
            />
          </Flex>
        </Box>
      </Flex>
    </Stack>
  )
}

ProductsListItem.displayName = TAG

export default ProductsListItem
